import React, { useState } from "react"
import { Helmet } from "react-helmet"
import {
  NavBar,
  Footer,
} from '../components'
import {
  H1,
  Container
} from '../styles/components'
import Gallery from 'react-photo-gallery'
import ReactBnbGallery from 'react-bnb-gallery'
import '../styles/gallery.css'
import {
  residentialPhotos, residentialPhotosBnb,
  commercialPhotos, commercialPhotosBnb,
  officePhotos, officePhotosBnb,
  hospitalityPhotos, hospitalityPhotosBnb
} from '../components/gallery'
import logo from '../images/logo_black.png'

export default function GalleryPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  return (
    <div className="galleryPage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Turnkey | Gallery</title>
        <link rel="canonical" href="https://turnkey-egy.com/gallery" />
      </Helmet>
      <NavBar activePage="gallery" background="white" logo={logo} />
      <Container>
        <H1>Hospitality</H1>
        <Gallery 
          photos={hospitalityPhotos} 
          onClick={(event, object) => {
            setActive(object.index);
            setIsOpen('hosp');
          }}
        />
        <ReactBnbGallery
          show={isOpen === 'hosp'}
          photos={hospitalityPhotosBnb}
          activePhotoIndex={active}
          onClose={() => setIsOpen(false)}
        />
      </Container>
      <Container>
        <H1>Commercial</H1>
        <Gallery 
          photos={commercialPhotos} 
          onClick={(event, object) => {
            setActive(object.index);
            setIsOpen('comm');
          }}
        />
        <ReactBnbGallery
          show={isOpen === 'comm'}
          photos={commercialPhotosBnb}
          activePhotoIndex={active}
          onClose={() => setIsOpen(false)}
        />
      </Container>
      <Container>
        <H1>Office spaces</H1>
        <Gallery 
          photos={officePhotos} 
          onClick={(event, object) => {
            setActive(object.index);
            setIsOpen('office');
          }}
        />
        <ReactBnbGallery
          show={isOpen === 'office'}
          photos={officePhotosBnb}
          activePhotoIndex={active}
          onClose={() => setIsOpen(false)}
        />
      </Container>
      <Container>
        <H1>Residential</H1>
        <Gallery 
          photos={residentialPhotos} 
          onClick={(event, object) => {
            setActive(object.index);
            setIsOpen('res');
          }}
        />
        <ReactBnbGallery
          show={isOpen === 'res'}
          photos={residentialPhotosBnb}
          activePhotoIndex={active}
          onClose={() => setIsOpen(false)}
        />
      </Container>
      <Container>
        <span style={{ float: "revert", fontStyle: "italic" }}>
          Disclaimer:<br />
          Designs and products showcased on this page were produced by Turnkey founding members
          with their previous employers, and should only be viewed as examples of the breadth and range
          of experience of the Turnkey team.
        </span>
      </Container>
      <Footer />
    </div>
  )
}
