import commercial1 from '../images/gallery/commercial/14d6f796-9b05-442c-8202-87ae6964a894.jpg'
import commercial2 from '../images/gallery/commercial/602c8c62-37da-45f6-b1b4-fc514e0915d5.jpg'
import commercial3 from '../images/gallery/commercial/f3647b40-78d2-424b-b54d-f5352ec2c27d 2.jpg'
import commercial4 from '../images/gallery/commercial/WhatsApp Image 2020-09-07 at 3.53.03 PM (3).jpeg'
import commercial5 from '../images/gallery/commercial/WhatsApp Image 2020-09-07 at 3.53.03 PM (4).jpeg'
import commercial6 from '../images/gallery/commercial/WhatsApp Image 2020-09-07 at 3.53.03 PM (5).jpeg'
import commercial7 from '../images/gallery/commercial/MK3T5115.jpg'
import commercial8 from '../images/gallery/commercial/MK3T5123.jpg'
import commercial9 from '../images/gallery/commercial/MK3T5137.jpg'
import hospitality1 from '../images/gallery/hospitality/95913677.jpg'
import hospitality2 from '../images/gallery/hospitality/96249587.jpg'
import hospitality3 from '../images/gallery/hospitality/96250363.jpg'
import hospitality4 from '../images/gallery/hospitality/134118515.jpg'
import hospitality5 from '../images/gallery/hospitality/136212744.jpg'
import hospitality6 from '../images/gallery/hospitality/136212765.jpg'
import hospitality7 from '../images/gallery/hospitality/187448590.jpg'
import hospitality8 from '../images/gallery/hospitality/223585505.jpg'
import hospitality9 from '../images/gallery/hospitality/236229175.jpg'
import hospitality10 from '../images/gallery/hospitality/DSCF8448.jpg'
import hospitality11 from '../images/gallery/hospitality/DSCF8482.jpg'
import hospitality12 from '../images/gallery/hospitality/DSCF8550.jpg'
import office1 from '../images/gallery/office/[edgeinnovationcenter.com][198]booking-modal.jpg'
import office2 from '../images/gallery/office/[edgeinnovationcenter.com][218]8-1.jpg'
import office3 from '../images/gallery/office/[edgeinnovationcenter.com][411]4-2.jpg'
import office4 from '../images/gallery/office/[edgeinnovationcenter.com][523]3-6.jpg'
import office5 from '../images/gallery/office/[edgeinnovationcenter.com][718]5-2.jpg'
import office6 from '../images/gallery/office/[edgeinnovationcenter.com][887]1-6.jpg'
import office7 from '../images/gallery/office/3ec57ca6-1d4f-4ae4-9fa9-fad00dfa2e1a.jpg'
import office8 from '../images/gallery/office/6ba26953-3ec1-4ea5-b171-b366610c5de7.jpg'
import office9 from '../images/gallery/office/499cef4d-8163-4c7d-a301-5aeb7de7665c.jpg'
import office10 from '../images/gallery/office/3c040b41-200c-4a75-ad89-5717ea1cd7c9.jpg'
import office11 from '../images/gallery/office/e0d278b7-3400-416c-b3a8-665f227ca17f.jpg'
import office12 from '../images/gallery/office/fd4017ba-ecf9-41e1-b4e0-28284e1ab950.jpg'
import office13 from '../images/gallery/office/IMG_3026.jpg'
import office14 from '../images/gallery/office/IMG_3029.jpg'
import office15 from '../images/gallery/office/IMG_3034.jpg'
import residential1 from '../images/gallery/residential/2-2.jpg'
import residential2 from '../images/gallery/residential/IMG_2434.jpg'
import residential3 from '../images/gallery/residential/IMG_2464.jpg'
import residential4 from '../images/gallery/residential/IMG_2468.jpg'
import residential5 from '../images/gallery/residential/IMG_2507.jpg'
import residential6 from '../images/gallery/residential/IMG_2517.jpg'
import residential7 from '../images/gallery/residential/OE9103020.jpg'
import residential8 from '../images/gallery/residential/OE9103093.jpg'
import residential9 from '../images/gallery/residential/OE9103136.jpg'
import residential10 from '../images/gallery/residential/JPEG image 142.jpeg'
import residential11 from '../images/gallery/residential/JPEG image 147.jpeg'
import residential12 from '../images/gallery/residential/JPEG image 163.jpeg'

export const commercialPhotos = [
  {
    src: commercial1,
    width: 4,
    height: 3
  },
  {
    src: commercial2,
    width: 4,
    height: 3
  },
  {
    src: commercial3,
    width: 4,
    height: 3
  },
  {
    src: commercial4,
    width: 4,
    height: 3
  },
  {
    src: commercial5,
    width: 4,
    height: 3
  },
  {
    src: commercial6,
    width: 4,
    height: 3
  },
  {
    src: commercial7,
    width: 4,
    height: 3
  },
  {
    src: commercial8,
    width: 4,
    height: 3
  },
  {
    src: commercial9,
    width: 4,
    height: 3
  }
];

export const commercialPhotosBnb = [
  {
    photo: commercial1
  },
  {
    photo: commercial2
  },
  {
    photo: commercial3
  },
  {
    photo: commercial4
  },
  {
    photo: commercial5
  },
  {
    photo: commercial6
  },
  {
    photo: commercial7
  },
  {
    photo: commercial8
  },
  {
    photo: commercial9
  }
];

export const hospitalityPhotos = [
  {
    src: hospitality1,
    width: 4,
    height: 3
  },
  {
    src: hospitality2,
    width: 4,
    height: 3
  },
  {
    src: hospitality3,
    width: 4,
    height: 3
  },
  {
    src: hospitality4,
    width: 4,
    height: 3
  },
  {
    src: hospitality5,
    width: 4,
    height: 3
  },
  {
    src: hospitality6,
    width: 4,
    height: 3
  },
  {
    src: hospitality7,
    width: 4,
    height: 3
  },
  {
    src: hospitality8,
    width: 4,
    height: 3
  },
  {
    src: hospitality9,
    width: 4,
    height: 3
  },
  {
    src: hospitality10,
    width: 4,
    height: 3
  },
  {
    src: hospitality11,
    width: 4,
    height: 3
  },
  {
    src: hospitality12,
    width: 4,
    height: 3
  }
];

export const hospitalityPhotosBnb = [
  {
    photo: hospitality1
  },
  {
    photo: hospitality2
  },
  {
    photo: hospitality3
  },
  {
    photo: hospitality4
  },
  {
    photo: hospitality5
  },
  {
    photo: hospitality6
  },
  {
    photo: hospitality7
  },
  {
    photo: hospitality8
  },
  {
    photo: hospitality9
  },
  {
    photo: hospitality10
  },
  {
    photo: hospitality11
  },
  {
    photo: hospitality12
  }
];

export const officePhotos = [
  {
    src: office1,
    width: 4,
    height: 3
  },
  {
    src: office2,
    width: 4,
    height: 3
  },
  {
    src: office3,
    width: 4,
    height: 3
  },
  {
    src: office4,
    width: 4,
    height: 3
  },
  {
    src: office5,
    width: 4,
    height: 3
  },
  {
    src: office6,
    width: 4,
    height: 3
  },
  {
    src: office7,
    width: 4,
    height: 3
  },
  {
    src: office8,
    width: 4,
    height: 3
  },
  {
    src: office9,
    width: 4,
    height: 3
  },
  {
    src: office10,
    width: 4,
    height: 3
  },
  {
    src: office11,
    width: 4,
    height: 3
  },
  {
    src: office12,
    width: 4,
    height: 3
  },
  {
    src: office13,
    width: 4,
    height: 3
  },
  {
    src: office14,
    width: 4,
    height: 3
  },
  {
    src: office15,
    width: 4,
    height: 3
  }
];

export const officePhotosBnb = [
  {
    photo: office1
  },
  {
    photo: office2
  },
  {
    photo: office3
  },
  {
    photo: office4
  },
  {
    photo: office5
  },
  {
    photo: office6
  },
  {
    photo: office7
  },
  {
    photo: office8
  },
  {
    photo: office9
  },
  {
    photo: office10
  },
  {
    photo: office11
  },
  {
    photo: office12
  },
  {
    photo: office13
  },
  {
    photo: office14
  },
  {
    photo: office15
  }
];

export const residentialPhotos = [
  {
    src: residential1,
    width: 4,
    height: 3
  },
  {
    src: residential2,
    width: 4,
    height: 3
  },
  {
    src: residential3,
    width: 4,
    height: 3
  },
  {
    src: residential4,
    width: 4,
    height: 3
  },
  {
    src: residential5,
    width: 4,
    height: 3
  },
  {
    src: residential6,
    width: 4,
    height: 3
  },
  {
    src: residential7,
    width: 4,
    height: 3
  },
  {
    src: residential8,
    width: 3,
    height: 4
  },
  {
    src: residential9,
    width: 4,
    height: 3
  },
  {
    src: residential10,
    width: 4,
    height: 3
  },
  {
    src: residential11,
    width: 4,
    height: 3
  },
  {
    src: residential12,
    width: 4,
    height: 3
  }
];

export const residentialPhotosBnb = [
  {
    photo: residential1
  },
  {
    photo: residential2
  },
  {
    photo: residential3
  },
  {
    photo: residential4
  },
  {
    photo: residential5
  },
  {
    photo: residential6
  },
  {
    photo: residential7
  },
  {
    photo: residential8
  },
  {
    photo: residential9
  },
  {
    photo: residential10
  },
  {
    photo: residential11
  },
  {
    photo: residential12
  }
];
